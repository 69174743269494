<template>
  <div class="box">
    <div class="info">
      <div class="isnew">
        <div class="version">
          <div class="tips">设计主网站</div>
          <div class="code">设计界面排版</div>
          <div class="time">DIY</div>
        </div>

        <div class="btn-box">
          <div class="operate-btn" @click="btn">去设计主网站</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import http from '@/utils/cloud'
export default {
  data() {
    return {}
  },
  methods: {
    ...mapMutations(['dropProject', 'setProject']),
    async btn() {
      const res = await http.GET('/jw-admin', {
        module: 'project',
        operation: 'getMainWebsite',
        id: '62515df5cec89c0001a5f23b'
      })

      this.setProject(res[0])
      localStorage.setItem('routerType', 'admin')
      this.$router.push('/mall/home/homeData?type=admin')
    }
  }
}
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: #f4f6f8;
  .info {
    width: 100%;
    height: 100%;
    padding: 40px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .isnew {
      padding: 140px;
      background: #e4edf3;
      border: 1px solid #e9edef;
      border-radius: 2px;
      width: 500px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .version {
        border-bottom: 1px solid #e9edef;
        padding-bottom: 20px;
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .tips {
          font-weight: bold;
          font-size: 30px;
          line-height: 20px;
          color: #19be6b;
          margin-bottom: 20px;
        }
        .code {
          font-weight: bold;
          font-size: 18px;
          line-height: 22px;
          color: #939799;
          margin-bottom: 10px;
        }
        .time {
          font-size: 16px;
          line-height: 16px;
          color: #939799;
        }
      }
      .update {
        border-bottom: 1px solid #e9edef;
        padding-bottom: 20px;
        ul {
          li {
            font-size: 14px;
            display: flex;
            margin-top: 20px;
            list-style: none;
            .left {
              width: 40px;
              height: 20px;
              border-radius: 2px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 10px;
              flex-shrink: 0;
              &.add {
                color: #197be1;
                background: #e8eeff;
              }
              &.optimization {
                color: #19be6b;
                background: #edfff3;
              }
              &.repair {
                color: #ff9900;
                background: #fff9e6;
              }
              &.repair {
                color: #ff9900;
                background: #fff9e6;
              }
            }
            .right {
              flex: 1;
              font-size: 14px;
              line-height: 20px;
              color: #262b30;
            }
          }
        }
      }
      .upgrade-status {
        padding-top: 20px;
        .item {
          display: flex;
          align-items: center;
          font-size: 12px;
          color: rgb(147, 151, 153);
          line-height: 16px;
          margin-top: 20px;
          .icon {
            font-size: 14px;
            padding-right: 4px;
            color: #f90;
          }
          .text-warn {
            font-size: 14px;
            line-height: 20px;
            color: #262b30;
            span {
              color: #f90;
            }
          }
        }
        .instructions {
          padding: 7px 9px;
          border-radius: 2px;
          margin: 0;
          font-size: 12px;
          line-height: 16px;
          color: #636669;
          border: 1px solid #abdcff;
          background-color: #f0faff;
          > div {
            font-size: 12px;
            line-height: 16px;
            vertical-align: middle;
          }
          .text {
            font-weight: bold;
            margin-bottom: 6px;
          }
        }
      }
      .btn-box {
        display: flex;
        align-items: center;
        margin-top: 20px;
        padding-bottom: 20px;
        .operate-btn {
          width: 300px;
          text-align: center;
          font-size: 20px;
          line-height: 17px;
          color: #ffffff;
          cursor: pointer;
          background-color: #2d8cf0;
          padding: 16px 56px;
          border-radius: 2px;
        }
      }
    }
  }
}
</style>
